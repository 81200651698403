import type { FC, MouseEventHandler } from "react";
import { IconX } from "@web-monorepo/dds-icons";
import styles from "./XButton.module.css";

type Props = {
  className?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const XButton: FC<Props> = ({ className, onClick }) => (
  <button className={[styles.xButton, className].join(" ")} onClick={onClick}>
    <IconX size="s" color={"dt_content_primary"} />
  </button>
);

export default XButton;
